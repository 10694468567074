<script setup lang="ts">
const features = [
  {
    title: "installations",
    icon: "ph-lightbulb-filament",
  },
  {
    title: "energy",
    icon: "ph-lightning",
  },
  {
    title: "support",
    icon: "ph-headset",
  },
];
</script>

<template>
  <section class="py-10 layout lg:py-20" id="about">
    <div
      class="grid items-center grid-cols-1 gap-y-10 lg:grid-cols-2 lg:gap-x-32 lg:gap-y-0"
    >
      <div class="relative lg:col-span-1 h-[400px] lg:h-[650px] group">
        <!-- <img
          src="../assets/images/about_us.webp"
          alt="about smart systems marrakech"
          loading="lazy"
          class="hidden object-cover object-center w-full h-[650px] rounded bg-dark lg:block"
        /> -->

        <img
          src="../assets/images/about_us_mobile.webp"
          alt="about smart systems marrakech"
          class="object-cover object-center w-full h-[400px] rounded bg-dark lg:h-[650px]"
          loading="lazy"
        />

        <div
          class="hidden top-8 -z-10 -right-8 w-[99%] bg-primary/10 h-[650px] rounded ease-in-out duration-500 transition-transform group-hover:-translate-x-4 group-hover:-translate-y-4 lg:absolute lg:block"
        ></div>
      </div>

      <div class="flex flex-col lg:col-span-1 gap-y-4">
        <h2
          class="text-sm font-medium uppercase sub-title text-primary"
          data-aos="fade-up"
          data-aos-duration="700"
        >
          {{ $t("common.headings.about_us") }}
        </h2>
        <span class="w-10 underline" data-aos="fade-up" data-aos-duration="700"></span>
        <h2
          class="max-w-lg title text-dark lg:text-4xl"
          data-aos="fade-up"
          data-aos-duration="600"
        >
          {{ $t("common.about.title") }}
        </h2>
        <p
          class="max-w-xl paragraph text-gray"
          data-aos="fade-up"
          data-aos-duration="700"
        >
          {{ $t("common.about.description") }}
        </p>

        <div class="flex flex-col max-w-xl py-4">
          <div
            class="relative flex items-center px-4 py-4 border-b cursor-pointer group gap-x-4 border-gray/20 first:border-t"
            v-for="(feature, index) in features"
            :key="index"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <component :is="feature.icon" class="z-10 w-4 h-4 text-primary" />

            <p class="z-10 text-base font-medium text-dark paragraph">
              {{ $t("common.about." + feature.title) }}
            </p>

            <div
              class="absolute inset-0 w-0 transition-all duration-1000 ease-in-out bg-primary/10 group-hover:w-full"
            ></div>
          </div>
        </div>

        <div
          class="flex flex-col gap-y-4 lg:gap-y-0 lg:gap-x-8 lg:flex-row lg:items-center"
        >
          <div data-aos="fade-up" data-aos-duration="600">
            <router-link
            :to="{ name: 'about' }"
              class="btn btn-primary btn-icon"
              aria-label="about smart systems"
            >
              <ph-info class="w-5 h-5 text-white" weight="fill" />
              {{ $t("common.actions.learn_more") }}
            </router-link>
          </div>

          <div data-aos="fade-up" data-aos-duration="600">
            <a
              href="tel:+212608080323"
              class="flex items-center justify-center px-4 py-2 cursor-pointer gap-x-4 color-transition hover:bg-primary/5 lg:justify-normal"
              aria-label="call smart systems"
            >
              <div class="items-center justify-center p-4 rounded-full bg-primary/10">
                <ph-phone class="w-5 h-5 text-primary" weight="fill" />
              </div>

              <div class="flex flex-col">
                <p class="paragraph text-gray">
                  {{ $t("common.sub_headings.have_questions") }}
                </p>
                <p class="font-sans text-base italic font-bold paragraph text-dark">
                  +212 6 0808 0323
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
