<script setup lang="ts"></script>

<template>
  <section class="h-[550px] relative lg:h-[650px]">
    <div class="z-40 h-full text-white bg-dark/80">
      <div class="flex flex-col items-start justify-center h-full gap-y-5 layout">
        <h2 class="text-sm uppercase sub-title">
          {{ $t("home.herobanner.sub_title") }}
        </h2>
        <span class="w-16 underline"></span>
        <h1 class="title">
          {{ $t("home.herobanner.title") }}
        </h1>
        <p class="max-w-xl paragraph">
          {{ $t("home.herobanner.description") }}
        </p>
        <div>
          <router-link
          :to="{ name: 'about' }"
            class="px-8 btn btn-primary btn-icon"
            aria-label="about smart systems"
          >
            <ph-info class="w-5 h-5 text-white" weight="fill" />
            {{ $t("common.actions.discover_more") }}
          </router-link>
        </div>
      </div>
    </div>

    <div class="absolute inset-0 -z-10">
      <!-- <img
        class="hidden object-cover object-center w-full h-full lg:block"
        src="../assets/images/hero_banner.webp"
        alt="smart systems sarl marrakech"
        preload="true"
      /> -->
      <img
        class="object-cover object-center w-full h-full"
        src="../assets/images/hero_banner_mobile.webp"
        alt="smart systems sarl marrakech"
        preload="true"
      />
    </div>
  </section>
</template>

<style scoped></style>
