<script setup lang="ts">
const services = [
  {
    title: "title",
    description: "description",
    icon: "ph-lightbulb",
  },
  {
    title: "title",
    description: "description",
    icon: "ph-television-simple",
    rotate: true,
  },
  {
    title: "title",
    description: "description",
    icon: "ph-monitor",
  },
  {
    title: "title",
    description: "description",
    icon: "ph-first-aid",
  },
];
</script>

<template>
  <section class="bg-light-gray" id="services">
    <div class="py-10 layout lg:py-20">
      <div class="grid grid-cols-1 lg:grid-cols-2">
        <div class="flex flex-col items-start col-span-1 gap-y-4">
          <h2
            class="text-sm font-medium uppercase sub-title text-primary"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            {{ $t("common.headings.services") }}
          </h2>
          <span class="w-10 underline" data-aos="fade-up" data-aos-duration="700"></span>
          <h2
            class="max-w-lg title lg:text-4xl"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            {{ $t("common.services.title") }}
          </h2>
          <p
            class="max-w-xl paragraph text-gray"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            {{ $t("common.services.description") }}
          </p>
          <div data-aos="fade-up" data-aos-duration="800">
            <router-link
            :to="{ name: 'products' }"
              class="btn btn-primary btn-icon"
              aria-label="smart systems products"
            >
              <ph-bookmark class="w-5 h-5 text-white" weight="fill" />
              {{ $t("common.actions.get_started") }}
            </router-link>
          </div>
        </div>

        <div class="col-span-1 mt-6 lg:mt-0">
          <div
            class="grid items-center justify-center h-full grid-cols-1 gap-y-4 lg:gap-4 lg:grid-cols-2"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <div
              class="flex flex-col w-full h-full col-span-1 p-6 transition-all duration-500 ease-in-out bg-white border rounded cursor-pointer border-gray/20 gap-y-4 group hover:bg-primary hover:shadow-xl hover:-translate-y-2"
              v-for="(service, index) in services"
              :key="index"
            >
              <component
                :is="service.icon"
                class="w-12 h-12 color-transition group-hover:text-white"
                :class="service.rotate ? 'rotate-180' : ''"
                weight="thin"
              ></component>
              <h2 class="text-xl font-medium color-transition group-hover:text-white">
                {{ $t("common.services.features." + (index + 0) + "." + service.title) }}
              </h2>
              <p
                class="text-base paragraph text-gray color-transition group-hover:text-white"
              >
                {{
                  $t(
                    "common.services.features." + (index + 0) + "." + service.description
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
