<script setup lang="ts">
import { computed, ref } from "vue";
import LanguageDropdown from "./shared/LanguageDropdown.vue";
import { i18n } from "@/main";

const showMenu = ref(false);

const navLinks = computed(() => {
  const locale = i18n.global.locale;
  return [
    {
      name: "home",
      url: "/",
    },
    {
      name: "about",
      url: "/about",
    },
    {
      name: "services",
      url: `/?lang=${locale}#services`,
    },
    {
      name: "products",
      url: "/products",
    },
    {
      name: "contact",
      url: `/?lang=${locale}#contact`,
    },
  ];
});
</script>

<template>
  <nav class="py-2 lg:py-4 layout">
    <div class="flex items-center justify-between">
      <div
        class="flex items-center justify-between w-full lg:w-auto lg:gap-x-4 xl:gap-x-6 lg:justify-normal"
      >
        <router-link :to="{ name: 'home' }" aria-label="home">
          <img
            class="object-center object-contain w-[85px] h-[85px]"
            src="../assets/images/logo.webp"
            alt="smart systems marrakech logo"
          />
        </router-link>

        <div class="flex items-center">
          <!-- Menu Button -->
          <div class="p-1 lg:hidden">
            <LanguageDropdown />
          </div>

          <button
            class="p-1 lg:hidden"
            aria-label="display-menu"
            @click="showMenu = true"
          >
            <ph-list class="w-6 h-6" />
          </button>
        </div>

        <div class="hidden lg:items-center lg:gap-x-8 xl:gap-x-12 lg:flex">
          <!-- Divider -->
          <span class="h-14 w-0.5 bg-gray/10 rounded-full"></span>

          <ul
            class="list-none"
            role="list"
            v-for="(link, index) in navLinks"
            :key="index"
          >
            <li>
              <router-link
                :to="link.url"
                class="transition-all duration-300 ease-in-out link group"
                :aria-label="'smart systems' + link.name"
              >
                <span
                  class="bg-left-bottom bg-gradient-to-r from-primary to-primary bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out"
                >
                  {{ $t("common.navigation." + link.name) }}
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="hidden lg:flex lg:items-center lg:gap-x-2">
        <a
          href="tel:+212608080323"
          aria-label="smart systems phone number"
          class="btn btn-icon btn-primary"
        >
          <ph-phone class="w-5 h-5" weight="fill" />
          {{ $t("common.actions.give_us_a_call") }}
        </a>
        <a
          href="mailto:smart-systems@gmail.com"
          class="btn btn-icon btn-primary"
          aria-label="smart systems email"
        >
          <ph-envelope-simple class="w-5 h-5" weight="fill" />
          {{ $t("common.actions.send_a_message") }}
        </a>
        <LanguageDropdown />
      </div>
    </div>

    <!-- Mobile Menu -->
    <Transition :duration="550" name="nested">
      <section class="fixed inset-0 z-40 px-4 py-2 bg-white outer" v-if="showMenu">
        <div class="flex items-center justify-between">
          <router-link
            aria-label="home page"
            @click="showMenu = false"
            :to="{ name: 'home' }"
          >
            <img
              class="object-center object-contain w-[85px] h-[85px]"
              src="../assets/images/logo.webp"
              alt="smart systems marrakech logo"
            />
          </router-link>

          <button aria-label="close-menu" @click="showMenu = false">
            <ph-x class="w-6 h-6" />
          </button>
        </div>

        <div class="flex flex-col my-8 inner">
          <ul role="list" v-for="(link, index) in navLinks" :key="index">
            <li class="border-b border-gray/10">
              <router-link
                :to="link.url"
                class="block w-full py-4 text-lg capitalize link"
                :aria-label="link.name"
                @click="showMenu = false"
              >
                {{ $t("common.navigation." + link.name) }}</router-link
              >
            </li>
          </ul>
        </div>

        <div class="flex flex-col gap-y-4">
          <a
            href="tel:+212608080323"
            class="btn btn-icon btn-primary"
            aria-label="smart systems phone number"
          >
            <ph-phone class="w-6 h-6" weight="fill" />

            {{ $t("common.actions.give_us_a_call") }}
          </a>
          <a
            href="mailto:smart-systems@gmail.com"
            class="btn btn-icon btn-primary"
            aria-label="smart systems email"
          >
            <ph-envelope-simple class="w-6 h-6" weight="fill" />

            {{ $t("common.actions.send_a_message") }}
          </a>
        </div>
      </section>
    </Transition>
  </nav>
</template>

<style scoped>
.nested-enter-active,
.nested-leave-active {
  transition: all 0.3s ease-in-out;
}

.nested-leave-active {
  transition-delay: 0.25s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}
.nested-enter-active .inner {
  transition-delay: 0.25s;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0.001;
}
</style>
