<script setup>
import AnimationTransition from "./AnimationTransition.vue";

const props = defineProps(["isLoading"]);
</script>

<template>
  <AnimationTransition>
    <section
      v-if="props.isLoading"
      class="fixed inset-0 z-50 flex items-center justify-center w-full h-screen overflow-hidden bg-primary"
    >
      <div
        class="inline-block h-14 w-14 text-white animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      ></div>
    </section>
  </AnimationTransition>
</template>

<style scoped></style>
