<script setup lang="ts"></script>

<template>
  <section class="relative overflow-hidden bg-primary">
    <div class="group layout">
      <div class="grid items-center lg:grid-cols-2">
        <div
          class="flex flex-col items-start justify-center col-span-1 py-10 text-white lg:px-0 gap-y-6 lg:py-28"
        >
          <h2
            class="max-w-2xl uppercase title lg:text-3xl"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            {{ $t('home.cta.title') }}
          </h2>
          <p class="max-w-xl paragraph" data-aos="fade-up" data-aos-duration="600">
            {{ $t('home.cta.description') }}
          </p>

          <div class="flex flex-col w-full gap-y-6 lg:gap-x-4 lg:gap-y-0 lg:flex-row">
            <div data-aos="fade-up" data-aos-duration="700">
              <a
                href="tel:+212608080323"
                class="font-bold btn-icon btn btn-secondary"
                aria-label="smart systems phone number"
              >
                <ph-phone class="w-5 h-5" weight="fill" />
                {{ $t('common.actions.give_us_a_call') }}
              </a>
            </div>
            <div data-aos="fade-up" data-aos-duration="700">
              <a
                class="font-bold btn-icon btn btn-secondary"
                aria-label="smart systems email"
                href="mailto:smart-systems@gmail.com"
              >
                <ph-envelope-simple class="w-5 h-5" weight="fill" />
                {{ $t('common.actions.send_a_message') }}
              </a>
            </div>
          </div>
        </div>

        <div
          class="absolute left-0 right-0 w-10 h-full mx-auto -skew-x-[20deg] bg-white hidden lg:block"
        ></div>
        <div
          class="flex flex-col items-end justify-center h-full col-span-1"
        >
          <div class="relative w-full mb-8 bg-white rounded xl:w-[550px] lg:w-[400px] h-80">
            <img
              src="../assets/images/cta_banner.webp"
              class="object-contain object-center w-full h-full rounded"
              alt="smart systems marrakech"
            />
            <div class="absolute inset-0 rounded bg-dark/5"></div>
          </div>
        </div>
      </div>
      <div
        class="absolute transition-transform duration-500 ease-in-out rounded-full bg-white/20 -bottom-48 -left-40 w-96 h-96 group-hover:scale-[1.1]"
      ></div>
      <div
        class="absolute transition-transform duration-500 ease-in-out rounded-full bg-white/20 -top-48 -right-40 w-96 h-96 group-hover:scale-[1.1]"
      ></div>
    </div>
  </section>
</template>

<style scoped></style>
