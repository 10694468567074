import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import './assets/main.css';
import PhosphorIcons from "@phosphor-icons/vue";
import { createI18n } from 'vue-i18n';
// @ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';

const i18n = createI18n({
    legacy: true,
    locale: 'en',
    messages: {
        en: {
            home: require('./lang/en/home.json'),
            common: require('./lang/en/common.json'),
            products: require('./lang/en/products.json')
        },
        fr: {
            home: require('./lang/fr/home.json'),
            common: require('./lang/fr/common.json'),
            products: require('./lang/fr/products.json')
        },
    }
});

AOS.init({
    once: true
});

createApp(App).use(router).use(i18n).use(PhosphorIcons).mount("#app");

export { i18n };
