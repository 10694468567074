<script setup lang="ts">
import TheNavbar from "./components/TheNavbar.vue";
import TheFooter from "./components/TheFooter.vue";
import { onBeforeUnmount, onMounted, ref } from "vue";

const isAtTop = ref(true);

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const checkScrollPosition = () => {
  isAtTop.value = window.scrollY >= 100;
};

onMounted(() => {
  window.addEventListener("scroll", checkScrollPosition);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", checkScrollPosition);
});
</script>

<template>
  <Transition>
    <button
      class="fixed z-20 p-3 bg-white border rounded shadow-md text-dark bottom-6 right-6 border-gray/20 md:bottom-10 md:right-10 color-transition hover:bg-primary hover:text-white"
      @click="scrollToTop"
      v-if="isAtTop"
      aria-label="scroll to top"
    >
      <ph-caret-up class="w-5 h-5" weight="fill" />
    </button>
  </Transition>

  <Transition>
    <a
      href="https://wa.me/+212608080323"
      class="fixed z-20 p-2 text-white bg-[#38D956] border rounded shadow-md bottom-6 left-6 border-gray/5 md:bottom-10 md:left-10 color-transition hover:bg-[#38D956]/90"
      v-if="isAtTop"
      target="_blank"
      aria-label="smart systems whatsapp"
    >
      <ph-whatsapp-logo class="w-8 h-8" weight="fill" />
    </a>
  </Transition>

  <TheNavbar />
  <router-view />
  <TheFooter />
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
