import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { i18n } from "../main";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
    }
  },
  {
    path: "/products",
    name: "products",
    component: () =>
      import("../views/ProductsView.vue"),
    meta: {
      title: "Products",
    }
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import("../views/AboutView.vue"),
    meta: {
      title: "About",
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => 
      import("../views/NotFoundView.vue"),
    meta: {
      title: "Not found",
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    }
    return { top: 0, behavior: 'smooth' };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const lang = i18n.global.locale || 'en'; // Use the language from i18n or default to 'en'
  const newQuery = { ...to.query, lang }; // Preserve the language parameter in the new query
  if (to.query.lang !== lang) {
    next({ path: to.path, query: newQuery }); // Navigate to the same path with the updated query
  } else {
    next();
  }
});

export default router;
