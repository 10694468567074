<script setup lang="ts">
import { i18n } from '@/main';
import { computed } from 'vue';

const generalLinks = computed(() => {
  const locale = i18n.global.locale;
  return [
    {
      name: "about",
      url: "/about",
    },
    {
      name: "services",
      url: `/?lang=${locale}#services`,
    },
    {
      name: "products",
      url: "/products",
    },
    {
      name: "contact",
      url: `/?lang=${locale}#contact`,
    },
  ];
});

const contactLinks = [
  {
    title: "smart-systems@gmail.com",
    icon: "ph-envelope-simple",
    url: "mailto:smart-systems@gmail.com",
  },
  {
    title: "+212 6 0808 0323",
    icon: "ph-phone",
    url: "tel:+212608080323",
  },
  {
    title: "Marrakech, north east guilez route 144 10000.",
    icon: "ph-map-pin-line",
    url: "",
  },
];

const socialLinks = [
  {
    title: "facebook link",
    icon: "ph-meta-logo",
    url: "https://www.facebook.com/SmartSystems.Marrakech/",
  },
  {
    title: "instagram link",
    icon: "ph-instagram-logo",
    url: "https://www.instagram.com/smart.systems_marrakech/",
  },
];
</script>

<template>
  <footer class="bg-dark">
    <div class="grid grid-cols-1 gap-10 py-10 lg:gap-x-20 layout lg:py-20 lg:grid-cols-3">
      <div class="flex flex-col col-span-1 gap-y-4">
        <router-link :to="{ name: 'home' }" aria-label="home">
          <img
            class="object-contain object-center w-24 h-24"
            src="../assets/images/logo.webp"
            alt="smart systems marrakech logo"
            loading="lazy"
          />
        </router-link>

        <p class="max-w-sm text-white/85 paragraph">
          {{ $t("common.footer.description")}}
        </p>
      </div>

      <div class="grid gap-y-10 lg:col-span-2 lg:grid-cols-3">
        <div class="flex flex-col col-span-1 lg:items-center">
          <div class="flex flex-col gap-y-6">
            <h2 class="text-lg text-white sub-title lg:text-xl">{{ $t("common.sub_headings.general_links")}}</h2>

            <ul class="flex flex-col list-none gap-y-4" role="list">
              <li v-for="(link, index) in generalLinks" :key="index">
                <router-link
                  class="text-lg footer-link text-white/90 group"
                  :to="link.url"
                  :aria-label="'smart systems' + link.name"
                  ><span
                    class="bg-left-bottom bg-gradient-to-r from-primary to-primary bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out"
                  >
                  {{ $t("common.navigation." + link.name) }}
                  </span></router-link
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="flex flex-col gap-y-6">
          <h2 class="text-lg text-white sub-title lg:text-xl">{{ $t("common.sub_headings.contact_links")}}</h2>

          <ul class="flex flex-col list-none gap-y-4" role="list">
            <li v-for="(link, index) in contactLinks" :key="index">
              <a
                class="flex flex-row text-lg gap-x-6 footer-link text-white/85"
                :href="link.url"
                :aria-label="link.title"
              >
                <div class="w-5 h-5 lg:w-6 lg:h-6">
                  <component :is="link.icon" class="w-6 h-6" weight="fill"></component>
                </div>
                {{ link.title }}
              </a>
            </li>
          </ul>
        </div>

        <div class="flex flex-col col-span-1 lg:items-center">
          <div class="flex flex-col lg:text-center gap-y-6">
            <h2 class="text-lg text-white sub-title lg:text-xl">{{ $t("common.sub_headings.social_links")}}</h2>

            <ul class="flex flex-row list-none gap-x-4" role="list">
              <li
                v-for="(link, index) in socialLinks"
                class="p-3 rounded-full cursor-pointer bg-gray/20 color-transition group hover:bg-gray/10"
                :key="index"
              >
                <a
                  class="text-white/85 color-transition group-hover:text-primary"
                  :href="link.url"
                  :aria-label="link.title"
                  target="_blank"
                >
                  <div class="w-6 h-6">
                    <component
                      :is="link.icon"
                      class="w-6 h-6"
                      weight="regular"
                    ></component>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col py-8 border-t gap-y-2 border-gray/80 layout lg:justify-center lg:items-center lg:flex-row"
    >
      <h2 class="text-base text-white/85 paragraph lg:text-center">
        © Smart-Systems {{ new Date().getFullYear() }}. {{ $t("common.sub_headings.rights_reserved")}}.
      </h2>
      <!-- <router-link
        to="/"
        aria-label="terms and conditions"
        class="text-base link text-white/85"
        >Terms and conditions</router-link
      > -->
    </div>
  </footer>
</template>

<style scoped></style>
