<script setup lang="ts">
import { ref } from "vue";
import axios from "axios";

const contactLinks = [
  {
    title: "smart-systems@gmail.com",
    icon: "ph-envelope-simple",
    url: "mailto:smart-systems@gmail.com",
  },
  {
    title: "+212 6 0808 0323",
    icon: "ph-phone",
    url: "tel:+212608080323",
  },
  {
    title: "Brooklyn Golden Street New York 8800 United States",
    icon: "ph-whatsapp-logo",
    url: "https://wa.me/+212608080323",
  },
];

// Refs for loading state and error/success toast visibility
const loading = ref(false);
const showErrorToast = ref(false);
const showSuccessToast = ref(false);

// Ref for form data and input errors
const formData = ref({
  fullname: "",
  email: "",
  subject: "",
  description: "",
});
const inputErrors = ref({
  fullname: false,
  email: false,
  subject: false,
  description: false,
});

// Function to validate form inputs
const validateForm = () => {
  inputErrors.value.fullname =
    !formData.value.fullname.trim() || formData.value.fullname.trim().length < 3;
  inputErrors.value.subject =
    !formData.value.subject.trim() || formData.value.subject.trim().length < 3;
  inputErrors.value.email =
    !formData.value.email.trim() || !validateEmail(formData.value.email);
  inputErrors.value.description =
    !formData.value.description.trim() || formData.value.description.trim().length < 10;

  return !(
    inputErrors.value.fullname ||
    inputErrors.value.subject ||
    inputErrors.value.email ||
    inputErrors.value.description
  );
};

// Function for email validation
const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Function to submit form
const submitForm = async () => {
  if (!validateForm()) {
    return;
  }

  showErrorToast.value = false;
  showSuccessToast.value = false;

  try {
    loading.value = true;
    await axios.post("https://formspree.io/f/xayrpjjv", formData.value);
    showSuccessToast.value = true;

    formData.value.fullname = "";
    formData.value.subject = "";
    formData.value.email = "";
    formData.value.description = "";
  } catch (error) {
    showErrorToast.value = true;
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <section class="bg-light-gray" id="contact">
    <div class="grid gap-8 py-10 layout lg:grid-cols-2 lg:py-20">
      <div
        class="relative flex flex-col col-span-1 px-6 py-10 overflow-hidden text-white rounded shadow-lg lg:shadow-2xl lg:px-8 lg:py-16 group gap-y-6 bg-primary"
      >
        <div class="flex flex-col items-start col-span-1 gap-y-6">
          <h2
            class="text-base text-white uppercase sub-title"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            {{ $t("common.headings.contact_experts") }}
          </h2>
          <span
            class="w-12 underline bg-white"
            data-aos="fade-up"
            data-aos-duration="700"
          ></span>
          <h2
            class="max-w-xl uppercase title lg:text-3xl"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            {{ $t("common.contact.title") }}
          </h2>
          <p class="max-w-xl paragraph" data-aos="fade-up" data-aos-duration="700">
            {{ $t("common.contact.description") }}
          </p>

          <div
            class="z-10 flex items-center justify-center w-full gap-x-4 lg:justify-normal lg:w-auto"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <a
              class="col-span-1 p-3 transition-all duration-500 ease-in-out bg-white rounded-full shadow-lg cursor-pointer text-dark hover:-translate-y-2 hover:bg-primary hover:text-white"
              v-for="(link, index) in contactLinks"
              :key="index"
              :href="link.url"
              :aria-label="link.title"
              target="_blank"
            >
              <component :is="link.icon" class="w-6 h-6" weight="fill"></component>
            </a>
          </div>
        </div>

        <div
          class="absolute w-32 h-32 transition-transform duration-700 ease-in-out rounded-full bg-white/20 -top-8 -right-8 group-hover:scale-125"
        ></div>
        <div
          class="absolute w-48 h-48 transition-transform duration-700 ease-in-out rounded-full bg-white/20 -bottom-20 -left-16 group-hover:scale-125"
        ></div>

        <div
          class="absolute inset-x-0 bottom-0 flex flex-row items-center justify-center w-full py-4 mx-auto gap-x-4"
        >
          <span class="w-6 h-1 rounded lg:w-8 lg:h-1.5 bg-white/30"></span>
          <span class="w-6 h-1 rounded lg:w-8 lg:h-1.5 bg-white/30"></span>
          <span class="w-6 h-1 rounded lg:w-8 lg:h-1.5 bg-white/30"></span>
        </div>
      </div>

      <div
        class="flex flex-col col-span-1 px-6 py-10 bg-white rounded shadow-lg lg:shadow-2xl lg:px-20 lg:py-16 gap-y-6"
      >
        <div class="flex flex-col gap-y-6">
          <h2
            class="text-base uppercase text-dark sub-title"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            {{ $t("common.sub_headings.fill_in_form") }}
          </h2>
          <span
            class="w-12 underline bg-dark"
            data-aos="fade-up"
            data-aos-duration="700"
          ></span>
        </div>
        <form class="flex flex-col gap-y-8" @submit.prevent>
          <div class="relative group" data-aos="fade-up" data-aos-duration="600">
            <input
              :placeholder="$t('common.placeholders.full_name')"
              type="text"
              class="w-full py-4 pl-10 text-base transition-all duration-500 ease-in-out border-b input border-dark/50 text-dark outline-0 focus:outline-0 focus:border-primary placeholder:text-gray placeholder:text-sm placeholder:uppercase"
              v-model="formData.fullname"
            />

            <div class="absolute bottom-0 left-0 top-4 lg:top-4">
              <ph-user
                class="w-6 h-6 text-dark/90 active:text-primary"
                weight="regular"
              />
            </div>

            <p
              v-if="inputErrors.fullname"
              class="mt-2 text-red-500 text-[0.875rem] normal-case"
            >
              {{ $t("common.errors.full_name_required") }}
            </p>
          </div>

          <div class="relative group" data-aos="fade-up" data-aos-duration="600">
            <input
              :placeholder="$t('common.placeholders.email_address')"
              type="email"
              class="w-full py-4 pl-10 text-base transition-all duration-500 ease-in-out border-b input border-dark/50 text-dark outline-0 focus:outline-0 focus:border-primary placeholder:text-gray placeholder:text-sm placeholder:uppercase"
              v-model="formData.email"
            />

            <div class="absolute bottom-0 left-0 top-4 lg:top-4">
              <ph-envelope-simple
                class="w-6 h-6 text-dark/90 active:text-primary"
                weight="regular"
              />
            </div>

            <p
              v-if="inputErrors.email"
              class="mt-2 text-red-500 text-[0.875rem] normal-case"
            >
              {{ $t("common.errors.email_required") }}
            </p>
          </div>

          <div class="relative group" data-aos="fade-up" data-aos-duration="600">
            <input
              :placeholder="$t('common.placeholders.subject')"
              type="text"
              class="w-full py-4 pl-10 text-base transition-all duration-500 ease-in-out border-b input border-dark/50 text-dark outline-0 focus:outline-0 focus:border-primary placeholder:text-gray placeholder:text-sm placeholder:uppercase"
              v-model="formData.subject"
            />

            <div class="absolute bottom-0 left-0 top-4 lg:top-4">
              <ph-info
                class="w-6 h-6 text-dark/90 active:text-primary"
                weight="regular"
              />
            </div>

            <p
              v-if="inputErrors.subject"
              class="mt-2 text-red-500 text-[0.875rem] normal-case"
            >
              {{ $t("common.errors.subject_required") }}
            </p>
          </div>

          <div class="relative group" data-aos="fade-up" data-aos-duration="600">
            <textarea
              :placeholder="$t('common.placeholders.how_we_can_help')"
              class="w-full py-4 pl-10 text-base transition-all duration-500 ease-in-out border-b resize-none min-h-28 max-h-40 input border-dark/50 text-dark outline-0 focus:outline-0 focus:border-primary placeholder:text-gray placeholder:text-sm placeholder:uppercase"
              v-model="formData.description"
            />

            <div class="absolute bottom-0 left-0 top-4 lg:top-3.5">
              <ph-pen class="w-6 h-6 text-dark/90 active:text-primary" weight="regular" />
            </div>

            <p
              v-if="inputErrors.description"
              class="mt-2 text-red-500 text-[0.875rem] normal-case"
            >
              {{ $t("common.errors.description_required") }}
            </p>
          </div>

          <div data-aos="fade-up" data-aos-duration="700">
            <button
              class="w-full py-5 btn btn-icon btn-primary"
              type="submit"
              aria-label="submit form"
              @click="submitForm"
            >
              <ph-circle-notch class="w-5 h-5 text-white animate-spin" v-if="loading" />
              <ph-paper-plane-tilt class="w-5 h-5 text-white" weight="fill" v-else />
              {{ $t("common.actions.get_in_touch") }}
            </button>

            <p class="mt-4 text-red-500 paragraph" v-if="showErrorToast">
              {{ $t("common.errors.submit_message") }}
            </p>
            <p class="mt-4 text-green-500 paragraph" v-if="showSuccessToast">
              {{ $t("common.success.submit_message") }}
            </p>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
